import React from "react"; 

export default function Bio() {
    return (
        <div>
            <p className="job">FRONTEND DEVELOPER BASED IN THE SF BAY AREA</p>
            <p className="statement">EXPERIENCED IN BUILDING APPLICATIONS USING JAVASCRIPT, REACT, AND MORE!</p>
        </div>
    )
}
